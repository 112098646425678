<template>
    <el-main>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="订单编号">
                <el-input size="small" v-model="form.order_no"></el-input>
            </el-form-item>
            <el-form-item label="技师姓名">
                <el-input size="small" v-model="form.artisan_name"></el-input>
            </el-form-item>
            <el-form-item label="联系电话">
                <el-input size="small" v-model="form.mobile"></el-input>
            </el-form-item>
            <el-form-item label="支付状态">
                <el-select size="small" v-model="form.status" placeholder="请选择" clearable>
                    <el-option label="未支付" value="-1"></el-option>
                    <el-option label="已支付" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="付款时间">
                <el-date-picker v-model="time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期"
                    end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
                <el-button size="small" @click="exportOrder">导出</el-button>
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }"
            :tree-props="{ children: '_child', hasChildren: 'hasChildren' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="order_no" label="订单编号" align="center"> </el-table-column>
            <!--<el-table-column prop="grade" label="服务区域" align="center"> </el-table-column>-->
            <el-table-column prop="artisan_name" label="技师姓名" align="center"></el-table-column>
            <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
            <el-table-column label="保证金额" align="center">
                <template slot-scope="scope">￥{{ scope.row.amount }}</template>
            </el-table-column>
            <el-table-column label="付款时间" align="center">
                <template slot-scope="scope">{{ getDateformat(scope.row.pay_time) }}</template>
            </el-table-column>
        </el-table>
        <Paging :total="total" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
    </el-main>
</template>

<script>
import config from '@/util/config';
//const industryName = '/Gateway'; //行业版本接口名称（用于更换重复接口）
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            form: {
                page: 1,
                rows: 10,
                order_no: '',
                artisan_name: '',
                mobile: '',
                status: '',
                start_time: '',
                end_time: ''
            },
            time: '',
            total: 0,
            list: []
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getDateformat,
        updateData (val, status) {
            if (status == 0) {
                this.form.rows = val;
                this.getList();
            } else {
                this.form.page = val;
                this.getList();
            }
        },
        getList (page = 0) {
            if (page) {
                this.form.page = 1
            }
            if (this.time) {
                this.form.start_time = this.time[0] / 1000
                this.form.end_time = this.time[1] / 1000
            } else {
                this.form.start_time = ''
                this.form.end_time = ''
            }
            this.$axios
                .post(this.$api.repair.finance.earnestMoneyOrderList, this.form)
                .then(res => {
                    if (res.code == 0) {
                        let { list, total_number } = res.result;

                        this.list = list;
                        this.total = total_number
                    }
                });
        },
        cancelSearch () {
            this.form = {
                page: 1,
                rows: 10,
                order_no: '',
                artisan_name: '',
                mobile: '',
                status: '',
                start_time: '',
                end_time: ''
            }
            this.time = ''
            this.getList()
        },
        exportOrder () {
            if (this.time) {
                this.form.start_time = this.time[0] / 1000
                this.form.end_time = this.time[1] / 1000
            } else {
                this.form.start_time = ''
                this.form.end_time = ''
            }
            let obj = {
                order_no: this.form.order_no,
                artisan_name: this.form.artisan_name,
                mobile: this.form.mobile,
                status: this.form.status,
                start_time: this.form.start_time,
                end_time: this.form.end_time,
            }
            this.$axios
                .post(this.$api.repair.finance.earnestMoneyOrderExport, obj)
                .then(res => {
                    if (res.code == 0) {
                        let path = config.baseurl + '/' + res.result;
                        let a = document.createElement('a');
                        a.href = path;
                        a.id = 'download';
                        document.body.appendChild(a);
                        a.click();
                        let aDom = document.getElementById('download');
                        document.body.removeChild(aDom);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .el-table {
        flex: 1;

        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }

        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }

    .edit {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }

        i {
            font-size: 17px;
        }
    }

    .move .item {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 20px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    // 这是点击后的旋转角度
    transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
    .el-icon-arrow-right:before {
        //这是收起图标
        content: '\e723';
        font-size: 16px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    .el-icon-arrow-right:before {
        // 这是展开图标
        content: '\e722';
        font-size: 16px;
    }
}
</style>
